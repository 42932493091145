<template>
	<div>
		<v-card>

			<v-data-table :headers="headers" :options.sync="options" :items="lista" :items-per-page="itemsPerPage"
				:loading="loadingTable" hide-default-footer :key="`list-sales-invoices-${tenantId}`" class="elevation-1">
				<template v-slot:top>
					<ExpandableFilters classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
						iconButtom="mdi-upload" classTitle="col-0" :filters=filters @datafilters=cargarLista(true) />
					<v-col>
						<v-btn color="primary" small class="ma-2 white--text" @click="exportar()">
							<v-icon left>mdi-microsoft-excel</v-icon>
							Exportar
						</v-btn>
						<v-btn class="ma-2 white--text" color='green' small target="_new" :to="`/nuevo-pago-recibido/`">
							<v-icon>mdi-plus</v-icon>
							Nuevo
						</v-btn>
					</v-col>


				</template>
				<template v-slot:item.opcion="{ item }">
					<v-btn icon color='red' target="_blank" :to="`/ver-pago/${item.payment_id}`">
						<v-icon color='#ff9800'>mdi-table-edit</v-icon>
					</v-btn>

				</template>

				<template v-slot:footer>
					<v-pagination class='mt-10' v-model="currentPage" :length="pageCount" @input="handlePageChange"
						total-visible=10></v-pagination>
				</template>

			</v-data-table>
		</v-card>
		 
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import ExpandableFilters from '../general/ExpandableFilters'

export default {
	name: "PagosRecibidosComponent",
	components: {
		ExpandableFilters
	},
	data: () => ({
		headers: [
			{ text: 'Opciones', value: 'opcion' },
			{ text: 'Codigo', value: 'payment_id' },
			{ text: 'Fecha', value: 'effective_date' },
			{ text: 'Tipo', value: 'tipo_pago' },
			{ text: 'Forma de pago', value: 'forma_pago' },
			{ text: 'Cliente', value: 'cliente' },
			{ text: 'Monto', value: 'amount', align: "end" },
			{ text: 'Saldo', value: 'saldo', align: "end" },
			{ text: 'Estado', value: 'estado' },
			{ text: 'Referencia', value: 'payment_ref_num' },
		],
		lista: [],
		options: {},
		dialog: false,
		notifications: false,
		overlay: false,
		cabecera: {
			invoice_date: "",
			participante: "",
			estado: ""
		},
		itemsEstado: [{ 'estadoId': 'INVOICE_READY', 'estadoNombre': 'Listo' }, { 'estadoId': 'INVOICE_PAID', 'estadoNombre': 'Pagado' }],
		itemsFactura: [],
		totalesFactura: [],
		headItems: [
			{ text: 'Opciones', value: 'opcion', align: "center", width: 50 },
			{ text: 'No.', value: 'secId', align: "start" },
			{ text: 'Descripcion', value: 'description', align: "start" },
			{ text: 'Cantidad', value: 'cantidad', align: "end" },
			{ text: 'Precio', value: 'valorUnitario', align: "end" },
			{ text: '%IVA', value: 'pct_iva', align: "end" },
			{ text: 'IVA', value: 'valor_iva', align: "end" },
			{ text: 'Total', value: 'total', align: "end" },
		],
		/*headPagos: [
			{ text: 'Tipo', value: 'payment_method_type', align: "center", width: 150 },
			{ text: 'Referencia', value: 'payment_ref_num', align: "start" },
			{ text: 'Fecha', value: 'effective_date', align: "start" },
			{ text: 'Monto', value: 'amount_applied', align: "start" },
		],*/
		filters: [
			{
				cols: 5,
				class: 'py-0 py-md-2 col-md-1 pl-0 pl-sm-2',
				v_model: '',
				label: 'Código',
				type: 'input_text'
			},
			{
				cols: 5,
				class: 'py-0 py-md-2 col-md-3 pr-0 pr-sm-2 mb-1',
				v_model: [],
				label: 'Fecha',
				type: 'input_date_time',
				clearable: true,
			},
			{
				cols: 5,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Tipo',
				type: 'input_select',
				text: 'tipoPagoNombre',
				value: 'tipoPagoId',
				clearable: true,
				multiple: true,
				items: [
					{ 'tipoPagoId': 'CUSTOMER_PAYMENT', 'tipoPagoNombre': 'Pago de cliente' },
					{ 'tipoPagoId': 'CRUCE_NC', 'tipoPagoNombre': 'Cruce con nota de crédito' },
					{ 'tipoPagoId': 'RETENCION', 'tipoPagoNombre': 'Retención' }
				]
			},
			{
				cols: 5,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Estado',
				type: 'input_select',
				text: 'estadoNombre',
				value: 'estadoId',
				clearable: true,
				items: [
					{ 'estadoId': 'PMNT_NOT_PAID', 'estadoNombre': 'En preparación' },
					{ 'estadoId': 'PMNT_RECEIVED', 'estadoNombre': 'Recibido' },
					{ 'estadoId': 'PMNT_VOID', 'estadoNombre': 'Cancelado' }
				],
			},
			{
				cols: 5,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2 mb-1',
				v_model: '',
				label: 'Cliente',
				type: 'input_text'
			},
			{
				cols: 5,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Forma de pago',
				type: 'input_select',
				text: 'description',
				value: 'payment_method_type_id',
				clearable: true,
				items: [],
			},
			{
				cols: 5,
				class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
				v_model: '',
				label: 'Referencia',
				type: 'input_text',
				clearable: true,
				items: [],
			},
		],
		pagos: [],
		total_pagado: "",
		solChequeDev: false,
		datosDevCheque: { refCosto: "", ref1: "", fin_account_id: "" },
		resRefCheque: "",
		refChequeEc: "",
		valorChequeEc: "",
		refCostoEc: "",
		costoChequeEc: "",
		currentPage: 1,
		pageCount: 1,
		itemsPerPage: 30,
		searchNumber: "",
		searchFechaInv: "",
		searchEstado: "",
		searchCliente: "",
		cuentasBancarias: [],
		finAccountRule: [
			v => !!v || 'El campo es obligatorio'
		],
		refRule:
			v => !!v || 'El campo es requerido'
		,
		existe_pago_cheque: false
	}),
	watch: {
		options: {
			handler() {
				this.cargarLista(false)
			},
			deep: true
		},
	},
	computed: {
		...mapState('master', ['loadingTable', 'user', 'tenantId']),

		...mapGetters('access', ['btnAbrirCaja']),

		refExiste() {
			if (this.valorChequeEc == 0 || this.valorChequeEc == "")
				return 'No se encontró la referencia'
			else
				return true
		},

		validarRefCosto() {
			if (this.datosDevCheque.refCosto != "" && (this.costoChequeEc == 0 || this.costoChequeEc == "")) {
				return 'No se encontró una transacción'
			} else {
				return true
			}
		}
	},
	methods: {
		...mapMutations('master', ['setUrl', 'setOverlay', 'setMenu', 'setLoadingTable', 'setTitleToolbar']),

		...mapActions('master', ['requestApi', 'alertNotification']),

		cargarLista(filtrar) {

			if (filtrar == true) {
				this.currentPage = 1
			}

			this.setLoadingTable(true)
			this.setUrl('pago-recibido')
			this.lista = []
			this.requestApi({
				method: 'GET',
				data: {
					"page": this.currentPage,
					"page_count": this.pageCount,
					"page_size": this.itemsPerPage,
					"paymentId": this.filters[0].v_model,
					"statusId": this.filters[3].v_model,
					"paymentDate": this.filters[1].v_model,
					"paymentMethodTypeId": this.filters[5].v_model,
					"paymentTypeId": this.filters[2].v_model,
					"refNum": this.filters[6].v_model,
					"cliente": this.filters[4].v_model
				}
			}).then(res => {


				this.lista = res.data.detail.pagos;
				this.pageCount = res.data.detail.page_count;
				this.setLoadingTable(false)

			}).then(() => {

			})


		},

		/*formInvoice(invid) {

			this.setUrl('invoice/' + invid)
			this.requestApi({
				method: 'GET',
				data: {}
			}).then(res => {

				console.log(res.data.cabecera, res.data.itemsFactura);
				this.cabecera = res.data.cabecera;
				this.itemsFactura = res.data.itemsFactura;
				this.totalesFactura = res.data.totales;
				this.pagos = res.data.pagos;
				this.total_pagado = res.data.total_pagado;
				this.existe_pago_cheque = res.data.existe_pago_cheque;
				this.dialog = true

			}).then(() => {

			})

		},*/

		handlePageChange(value) {
			//this.currentPage = value
			this.cargarLista(false)
		},

		cargarCuentasBancarias() {
			this.setLoadingTable(true)

			this.setUrl('cuentas-bancarias')
			this.requestApi({
				method: 'GET',
				data: {

				}
			}).then(res => {

				this.cuentasBancarias = res.data._embedded.cuentas_bancarias
				this.setLoadingTable(false)

			})
		},

		/*solicitarDevCheque() {
			this.datosDevCheque = { refCosto: "", ref1: "", fin_account_id: "" }

			this.refChequeEc = ""
			this.valorChequeEc = ""
			this.refCostoEc = ""
			this.costoChequeEc = ""
			this.solChequeDev = true
		},

		enviarSolicitudCheque() {
			//this.setLoadingTable(true)

			if (!this.$refs.formSolChequeDev.validate()) {
				return false
			}

			this.setUrl('nota-debito-cheque')
			this.requestApi({
				method: 'POST',
				data: {
					"ref1": this.refChequeEc,
					"ref2": this.refCostoEc,
					"party_id": this.cabecera.party_id,
					"invoice_id_from": this.cabecera.invoice_id,
					"fin_account_id": this.datosDevCheque.fin_account_id
				}
			}).then(res => {

				this.solChequeDev = false
				//this.formInvoice(res.notaDebitoId)

				this.alertNotification({
					param: {
						html: 'Nota de debito generada exitosamente',
						timer: 5000
					}
				})
				this.setLoadingTable(false)

			})
		},*/

		buscarRefBanco(codigo, doc) {

			this.setUrl('estado-cuenta')
			this.requestApi({
				method: 'POST',
				data: {
					"codigo": codigo,
					"documento": doc
				}
			}).then(res => {
				if (codigo == "001316" || codigo == "001305") {

					if (res.data.concil_estado_cuenta_id == "") {
						this.resRefCheque = "mdi-close-circle-outline"
						this.refChequeEc = ""
						this.valorChequeEc = ""
					} else {
						this.resRefCheque = "mdi-check-circle-outline"
						this.refChequeEc = res.data.concil_estado_cuenta_id
						this.valorChequeEc = res.data.monto
					}

				}
				if (codigo == "098426") {
					this.refCostoEc = res.data.concil_estado_cuenta_id
					this.costoChequeEc = res.data.monto
				}

				//this.setLoadingTable(false)

			})
		},

		/*getEstablishment(){

			this.setUrl('establecimiento')
			this.requestApi({
				method : 'GET',
				data : {}
			}).then(res=>{             

				this.filters[5].items= res.data._embedded.establecimiento

			}).then(()=>{
			    
			})

		},*/

		getFormasPago() {

			this.setUrl('tipo-pago/CUSTOMER_PAYMENT/formas-pago')
			this.requestApi({
				method: 'GET',
				data: {}
			}).then(res => {

				this.filters[5].items = res.data.detail

			}).then(() => {

			})

		},

		
		exportar() {

			this.overlay = true
			this.setUrl('pago-recibido')
			this.requestApi({
				method: 'POST',
				data: {		
					tipoTran: "exportarPagosExcel",			
					cliente: this.filters[4].v_model,
					paymentId: this.filters[0].v_model,
					statusId: this.filters[3].v_model,
					effectiveDate: this.filters[1].v_model,
					paymentRefNum: this.filters[6].v_model,
					paymentMethodTypeId: this.filters[5].v_model,
					page_size: 100000,
					page: 1
				}
			}).then(res => {

				// console.log(res.data._embedded.invoice[0])
				// let link = document.createElement("a");
				// link.href = res.data._embedded.invoice[0];
				// link.download = "Documentos.xlsx";
				// link.click();

				var a = document.createElement("a");
				a.href =
					"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
					res.data.detail.datos;
				a.download = res.data.detail.archivo;
				a.click();

			}).catch(() => {

			}).then(() => {
				this.overlay = false
			})


		}

	},

	mounted() {
		this.setTitleToolbar('PAGOS RECIBIDOS')
		//this.geEstablishment()
		this.getFormasPago()
		this.cargarCuentasBancarias()
	}

}

</script>
